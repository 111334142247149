import api from '../axios';
import config from '@/constants/config';

const resource = '/api/mp/settings/';

export default {
  userList: (page, paginate, sort = '', desc = true) =>
    api.get(
      `${resource}users?page=${page}&paginate=${paginate}&sort=${
        desc ? '' : '-'
      }${sort}`
    ),
  userManageList: (page, paginate, sort = '', desc = true) =>
    api.get(
      `${resource}users/user-management?page=${page}&paginate=${paginate}&sort=${
        desc ? '' : '-'
      }${sort}`
    ),

  invitationList: (filterQuery) =>
    api.get(`${resource}practice-invitations?${filterQuery}`),

  resendInvitation: (id) =>
    api.post(`${resource}practice-invitations/${id}/resend`),

  deleteInvitation: (id) => api.delete(`${resource}practice-invitations/${id}`),

  sendInvitation: (payload) =>
    api.post(`${resource}practice-invitations`, payload),

  sendBulkInvitations: (payload) =>
    api.post(`${resource}practice-invitations/bulk-invitations`, payload),

  sendBulkInvitationsToLocums: (payload) =>
    api.post(`${resource}practice-invitations/locum-invitations`, payload),

  getJobRoleList: (payload) => api.get('api/job-roles?page=1&paginate=49'),

  getFileType: () => api.get('api/file-type'),

  SystemJobRoleList: () => api.get(`${resource}users/roles`),

  getServicesToAdd: (page, paginate, filterQuery) =>
    api.get(
      `/api/mp/services/non-assigned?page=${page}&paginate=${paginate}&${filterQuery}`
    ),

  getServicesInPractice: () => api.get(`/api/mp/services`),

  updateServiceForPractice: (payload) =>
    api.post(`/api/mp/services/sync`, payload),

  removeServiceForPractice: (id) => api.delete(`/api/mp/services/${id}`),

  jobRoleAssignedList: () => api.get(`${resource}job-roles/assigned`),

  jobRoleNotAssignedList: (filterQuery) =>
    api.get(`${resource}job-roles/not-assigned?${filterQuery}`),

  getPracticeType: () => api.get(`/api/practice-types`),

  updateRoles: (payload) => api.post(`${resource}job-roles/sync`, payload),

  deleteJobRole: (id) => api.delete(`${resource}job-roles/${id}`),

  assignedAdminRole: (id, payload) =>
    api.put(`${resource}users/${id}`, payload),

  updateUnAvailabilities: (payload, id) =>
    api.post(`${resource}users/${id}/unavailability`, payload),
  updateWorkingHours: (payload, id) =>
    api.post(`/api/mp/settings/users/working-hours/${id}`, payload),

  jobRoleUpdate: (payload) => api.post(`${resource}job-roles/update`, payload),

  //pcn
  nonConnectedPcns: () => api.get('/pcn/connections/available-pcns'),

  connectedPcns: () => api.get('/pcn/connections/'),

  //shift templates
  createShiftTemplate: (payload) =>
    api.post(`${resource}shift_template`, payload),
  getShiftTemplate: (page, paginate) =>
    api.get(`${resource}shift_template?page=${page}&paginate=${paginate}`),
  editShiftTemplate: (id, payload) =>
    api.put(`${resource}shift_template/${id}/update`, payload),
  removeShiftTemplate: (id) =>
    api.delete(`${resource}shift_template/${id}/delete`),

  //rota changes
  changeStateCancelAllow: () =>
    api.post(`api/mp/practice/allow_shift_cancellation`),
  getPracticeShiftCancelState: () =>
    api.get(`api/mp/practice/allow_shift_cancellation_state`),

  changeStateVacant: () => api.post(`${resource}auto_approval_shifts?`),
  getPracticeShiftVacant: () => api.get(`${resource}auto_approval_shifts?`),

  changeStateMyCalendar: () => api.put(`api/mp/user/show-vacant-my-calender`),

  getPracticeShiftSwapState: () =>
    api.get(`api/mp/practice/allow_shift_swap_state`),
  changeStateSwapAllow: () => api.post(`api/mp/practice/allow_shift_swap`),

  //role
  createRole: (payload) => api.post(`${resource}roles`, payload),
  getRoles: (page, paginate) =>
    api.get(`${resource}roles?page=${page}&paginate=${paginate}`),
  getRolesList: () => api.get(`${resource}roles?`),
  editJob: (id, payload) => api.put(`${resource}roles/${id}/update`, payload),
  getRolesWithoutPagination: () => api.get(`${resource}roles`),
  // getFilterRoles: (id) =>
  // api.get(`${resource}roles/filterRole?role_id=${id}`),
};
